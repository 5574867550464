<template>
  <div>
    <b-modal id="successModal" title="Success" ok-only>The contract was sent to the client</b-modal>
    <b-modal id="errorModal" title="Error" ok-only>There was an error. Please let dev know.</b-modal>
    <b-container>
      <h2>Prepare for Contract</h2>
      <b-form-group
        class="mb-3"
        id="email"
        label="Enter email to pull prospect from CRM"
        label-for="email"
      >
        <b-form-input
          id="email"
          v-model="email"
          trim
          required
          type="email"
          pattern="([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})"
        ></b-form-input>
      </b-form-group>
      <b-button
        class="mb-3"
        block
        @click="getContact"
        variant="primary"
        :disabled="submittingContact"
        >Pull Contact <b-spinner v-if="submittingContact" small
      /></b-button>

      <b-card border-variant="white" align="center" v-if="gotData">
        <b-card-title>Imported Information</b-card-title>
      </b-card>
      <b-table outlined stacked :items="items" v-if="gotData"></b-table>
      <b-form-group
        class="mb-3"
        id="number-of-seats"
        label="Number of seats"
        label-for="number-of-seats"
        v-if="gotData"
      >
        <b-form-input
          id="number-of-seats"
          v-model="number_of_seats"
          required
          type="number"
          trim
        ></b-form-input>
      </b-form-group>

      <b-form-group
        class="mb-5"
        id="setup-fee"
        label="Setup Fee"
        label-for="setup-fee"
        v-if="gotData"
      >
        <b-input-group prepend="$">
          <b-form-input
            id="setup-fee"
            v-model="setup_fee"
            required
            type="number"
            min="500"
            trim
            step=".01"
          ></b-form-input>
        </b-input-group>
      </b-form-group>
      <b-form-group
        class="mb-5"
        id="plan-type"
        label="Select the Plan Type"
        label-for="plan-fee"
        v-if="gotData"
      >
        <b-form-select
          id="plan-fee"
          v-model="selected"
          :options="options"
          v-if="gotData"
        ></b-form-select>
      </b-form-group>
      <b-button
        class="mb-3"
        block
        @click="sendEmail"
        variant="primary"
        :disabled="submitting"
        v-if="gotData"
        >Send Client Contract Email <b-spinner v-if="submitting" small
      /></b-button>
    </b-container>
  </div>
</template>

<script>
const API_URL = process.env.VUE_APP_BACKEND_URL;
export default {
  name: 'App',
  components: {},

  data: function() {
    return {
      items: [],
      selected: null,
      options: [
        { value: null, text: 'Please select an option' },
        { value: 'Advisory', text: 'Advisory $25.00/month/seat' },
        { value: 'Advisory Pro', text: 'Advisory $50.00/month/seat' }
      ],
      name: '',
      gotData: false,
      initial_email: '',
      firm_name: '',
      first_name: '',
      last_name: '',
      email: '',
      address: '',
      phone: '',
      number_of_seats: '',
      setup_fee: '',
      submittingContact: false,
      submitting: false,
      firm_agile_id: ''
    };
  },
  methods: {
    async getContact() {
      let user = JSON.parse(localStorage.getItem('user'));
      var userHeader = {};
      if (user && user.accessToken) {
        //return { Authorization: 'Bearer ' + user.accessToken }; // for Spring Boot back-end
        userHeader = { 'x-access-token': user.accessToken }; // for Node.js Express back-end
      }
      this.submittingContact = true;
      
      try {
        var response = await this.$http.post(
          API_URL + 'api/sales/crmprospect',
          {
            email: this.email.trim()
          },
          {
            headers: userHeader
          }
        );
        //console.log('data');
        //console.log(response.data);
        this.items = [response.data];
        this.first_name = response.data.first_name;
        this.last_name = response.data.last_name;
        this.firm_name = response.data.firm_name;
        this.phone = response.data.phone;
        this.firm_agile_id = response.data.firm_agile_id;

   
        this.gotData = true;
      } catch (error) {
     
        this.submitting = false;
        this.$bvModal.show("errorModal");
     
      }
      this.submittingContact = false;
      this.gotData = true;
    },
    async sendEmail() {
      this.submitting = true;
      var numberOfSeats = this.number_of_seats;
      console.log();
      var intNumberOfSeats = parseInt(numberOfSeats);

      var setupFee = this.setup_fee;
      var intSetupFee = parseInt(setupFee);
      this.number_of_seats = intNumberOfSeats;
      this.setup_fee = intSetupFee;
      console.log('number of seats in: ' + this.number_of_seats.toString());
      console.log('setup fee: ' + this.setup_fee.toString());
      this.plan = this.selected;
      if (this.plan == 'Advisory') {
        this.monthly_fee = 25.0;
      } else {
        this.monthly_fee = 50.0;
      }
      console.log('this plan: ' + this.plan);

      let user = JSON.parse(localStorage.getItem('user'));
      var userHeader = {};
      if (user && user.accessToken) {
        //return { Authorization: 'Bearer ' + user.accessToken }; // for Spring Boot back-end
        userHeader = { 'x-access-token': user.accessToken }; // for Node.js Express back-end
      } else {
        return '';
      }
      if (this.plan != null && this.setup_fee > 0 && this.monthly_fee > 0) {
        //API_URL
        var urlForBackend = API_URL + 'api/sale/begin';
        console.log(urlForBackend);

        try {
          await this.$http.post(
            urlForBackend,
            {
              firm_agile_id: this.firm_agile_id.toString(),
              firm_name: this.firm_name,
              last_name: this.last_name,
              first_name: this.first_name,
              address: this.address,
              email: this.email,
              plan: this.plan,
              phone: this.phone,
              number_of_advisors: intNumberOfSeats,
              setup_fee: this.setup_fee,
              monthly_fee: this.monthly_fee
            },
            {
              headers: userHeader
            }
          );

          this.gotData = false;
          this.email = '';
          this.number_of_seats = '';
          this.setup_fee = '';
          this.selected = null;

          this.$bvModal.show("successModal");
        } catch (error) {
          
          this.$bvModal.show("errorModal");

          this.submitting = false;
        
        }
        this.submitting = false;
      } else {
        <b-alert show>All Fields Must Have a Correct Value</b-alert>;
      }
    }
  }
};
</script>

<style></style>
